.toolbar-visit {
  --background: #860606;
}

.content-visit {
  .main-card {
      display: flex;
      flex-wrap: wrap;
      margin: 0 !important;
      overflow: hidden;
  
      ion-col {
        display: flex;
        justify-content: center;
        padding: 4px;
      }
  
      ion-card {
        margin: 2px !important;
        margin-bottom: 5px;
        margin-top: 5px;
        width: 100%;
      }
  
    }
  
    .img-empty {
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
}
