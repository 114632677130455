ion-app{
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  border-style: none;
  border-color: inherit;
  border-width: 0px;
  display: block;


  ion-tab-bar {
      position: relative;
      width: 100%;
      margin: 0 auto;
      --background: #860606 !important;
      ion-tab-button {
          --color: var(--ion-color-medium);
          --color-selected: var(--ion-color-primary);
      
          &::before {
              background-color: transparent;
              display: block;
              content: "";
              margin: 0 auto;
              width: 20px;
              height: 2px;
          }
      
          &.tab-selected::before {
              background-color: var(--ion-color-primary);
          }
      }
  }
  
  ion-content {
    ion-button {
      --background: #4C0000;
      --border-radius: 50px;
      --padding-start: 10px;
      --padding-end: 10px;
      --padding-top: 20px;
      --padding-bottom: 20px;
      --margin-top: 5px;
      --background-activated: grey;
    }
    ion-img {
      width: auto;
      margin: auto;
      display: block;
    }
  }
  
  ion-toolbar {
    --background: #860606 !important;
    ion-avatar {
      width: 55px !important;
      height: 40px !important;
      max-width: 100px !important; //any size
      max-height: 100px !important; //any size 
      padding-left: 10px;
      padding-right: 5px;
    }
  }
  
  ion-footer{
    ion-item{
      --background: #860606 !important;
    }
  }
  
  .iconBadge {
     ion-badge {
      position: absolute;
      top: -0.1rem;
      right: -0.1rem;
      opacity: 0.9;
          font-size: 6pt;
  
      & ~ ion-icon {
        margin-right: 1.2rem;
      }
    }
  
  }
  
  .search {
    padding-top: 0px !important;
    .item{
      padding-left: 5px;
      padding-right: 10px;
      margin: 0 !important;
      --border-radius: 10px;
      --box-shadow: 0px 14px 25px rgba(0, 0, 0, 0.09);
      padding-bottom: 1px !important;
      padding-top: 1px !important;
  
      ion-icon {
        margin-right: 5px;
      }
  
      .input {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
        padding-left: 10px !important;
        border-left: 1px solid #F4F4F4;
      }
    }
  }

  ion-fab{
    .fab-up{
      --background: #D92E35;
    }
    ion-fab-list{
      ion-fab-button[data-desc] {
        position: relative;
      }
    
      ion-fab-button[data-desc]::after {
        position: absolute;
        content: attr(data-desc);
        z-index: 1;
        right: 55px;
        bottom: 4px;
        background-color: #D92E35;
        padding: 9px;
        border-radius: 15px;
        color: white;
        box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
      }
    }
  }
}