/* 
Generic Styling, for Desktops/Laptops 
*/
table { 
    width: 100%;
    border-top:5px solid white;
    border-right:5px solid white;
    border-left:5px solid white;
    border-bottom:5px solid white;
    p{
        font-size: 12px;
        padding: 0px 5px 0px 5px;
    }
}
/* Zebra striping */
tr:nth-of-type(odd) { 
    background: #eee; 
}

th { 
    background: #860606; 
    color: white; 
    font-weight: bold;
}

td, th { 
    border: 1px solid #ccc; 
}

p{
    font-size: 12px;
    padding: 0px 5px 0px 5px;
}
