.ion-modal {
    --border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;
    
}

.ion-modal.auto-height::part(content) {
    position: relative;
    top: 80px;
    bottom: 0px;
    height: 100%;
}

