.toolbar-form{
  --background: #860606;
  ion-label{
    span{
        font-size: 12px;
        display: block;
    }
  }
  ion-item{
    --background: #860606;
  }
}

.form-content{
  ion-text{
    display: block;
    span{
      font-size: 15px;
      display: block;
    }
    .type{
      font-size: 12px;
      display: block;
    }
  }
  .google-map {
    display: inline-block;
    width: 100%;
    height: 45vh;
    .gm-style .gmnoprint, .gmnoprint { display:none  }
  }

  ion-fab-button[data-desc] {
    position: relative;
  }

  ion-fab-button[data-desc]::after {
    position: absolute;
    content: attr(data-desc);
    z-index: 1;
    right: 55px;
    bottom: 4px;
    background-color: #D92E35;
    padding: 9px;
    border-radius: 15px;
    color: white;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
  }
}