.toolbar-backorder {
  --background: #860606;
  ion-item{
    --background: #860606;
  }
}

.content-backorder {
  .main-card {
    display: flex;
    flex-wrap: wrap;
    margin: 0 !important;
    overflow: hidden;
    
    ion-label {
      span {
        font-size: 10px;
        display: block;
      }
    }
    
    ion-card {
      margin: 2px !important;
      margin-bottom: 5px;
      margin-top: 5px;
      width: 100%;
    }

  }

  .img-empty {
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}