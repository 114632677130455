ion-toolbar {
  --background: #860606 !important;
  ion-avatar {
    width: 55px !important;
    height: 40px !important;
    max-width: 100px !important; //any size
    max-height: 100px !important; //any size 
    padding-left: 10px;
    padding-right: 5px;
  }
}