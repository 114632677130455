.login-content {
  --background: #860606;
  
  .contaier-login {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    ion-item {
      --background: #860606;
      border-bottom: 1px solid white;
    }

    .item-has-focus .label-floating {
      transform: translateY(10px) scale(0.85);
    }

    .logo{
        max-width: 50%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    ion-input {
      color: #ffffff;
    }

    ion-button {
      --background: white;
      --border-radius: 50px;
      --padding-start: 10px;
      --padding-end: 10px;
      --padding-top: 20px;
      --padding-bottom: 20px;
      --margin-top: 10px;
      --background-activated: grey;
      color: black;
    }

    ion-text {
      font-size: 10px
    }
  }
  
  .login-footer{
    background-color: #860606  !important;
    --background: #860606 !important;
    ion-icon{
      width:100%;
      display:flex;
      align-items:center;
      justify-content:center;

    }
  }
  
}

