.ion-content {
  position: relative;
  .card {
    margin: 0 auto;
    .header {
      height: 140px;
      .avatar {
        width: 160px;
        height: 100px;
        position: relative;
        margin: 0 auto;
  
        .img-avatar {
          position: absolute;
          bottom: calc(-1*(80px + 4px));
          width: 170px;
          height: 170px;
          border-radius: 50%;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
  
  .card-body {
    overflow: hidden;
    ion-button {
      --background: #4C0000;
      --border-radius: 50px;
      --padding-start: 10px;
      --padding-end: 10px;
      --padding-top: 20px;
      --padding-bottom: 20px;
      --margin-top: 5px;
      --background-activated: grey;
    }
    .user-meta {
      padding-top: 50px;
  
      .playername {
        font-size: 24px;
        font-weight: 600;
      }
  
      .country {
        font-size: 90%;
        color: #949ea6;
        margin: 0 auto;
      }
    }
  }
}