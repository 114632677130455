ion-content {
  ion-button {
    --background: #4C0000;
    --border-radius: 50px;
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 20px;
    --padding-bottom: 20px;
    --margin-top: 5px;
    --background-activated: grey;
  }
  ion-img {
    width: auto;
    margin: auto;
    display: block;
  }

}
