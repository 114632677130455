.toolbar-detail-customer{
  --background: #860606;
  ion-label{
    span{
        font-size: 12px;
        display: block;
    }
  }
  ion-item{
    --background: #860606;
  }
}

.detail-content{
  ion-text{
    display: block;
    span{
      font-size: 15px;
      display: block;
    }
    .type{
      font-size: 12px;
      display: block;
    }
  }
  .google-map {
    display: inline-block;
    width: 100%;
    height: 45vh;
    .gm-style .gmnoprint, .gmnoprint { display:none  }
  }
}
