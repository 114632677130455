.ion-modal {
    --border-radius: 10px;
    padding-left: 15px;
    padding-right: 15px;


  .search {
    width: 500px;
    .item-select{
      padding-left: 5px;
      padding-right: 10px;
      margin: 0 !important;
      --border-radius: 10px;
      --box-shadow: 0px 14px 25px rgba(0, 0, 0, 0.09);
      ion-icon {
        margin-right: 5px;
      }
  
      .input-select {
        padding-left: 10px !important;
        border-left: 1px solid #F4F4F4;
      }
  
      ion-badge {
        position: absolute;
        top: 2px;
        right: 8px;
        opacity: 0.9;
        font-size: 0.7em;
      }
    }
  
}

    
}

.ion-modal.auto-height::part(content) {
    position: relative;
    top: 80px;
    bottom: 0px;
    height: 100%;
}

