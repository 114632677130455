.toolbar-detail-visit{
  --background: #860606;
  ion-label{
    span{
        font-size: 12px;
        display: block;
    }
  }
  ion-item{
    --background: #860606;
  }
}

.detail-content{
  ion-text{
    display: block;
    span{
      font-size: 15px;
      padding: 1px;
      display: block;
    }
    .type{
      font-size: 12px;
      display: block;
    }
  }

  ion-button {
    --background: #860606;
    --border-radius: 60px;
    --padding-start: 10px;
    --padding-end: 10px;
    --padding-top: 20px;
    --padding-bottom: 20px;
    --margin-top: 5px;
    --background-activated: grey;
  }
}
